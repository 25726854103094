import React from "react"

type Props = {
    color?: string
}
function Spinner({color = "text-primary"}:Props) {
	return (
		<div className="text-center py-4">
			<div className={`spinner-border ${color}`} role="status">
				<span className="sr-only"></span>
			</div>
		</div>
	)
}

export default Spinner
