import React, {useContext, useEffect} from "react"
import {serverSideTranslations} from "next-i18next/serverSideTranslations"
import PropTypes from "prop-types"
import NavBar from "../components/navigation/NavBar"
import Footer from "../components/footer/Footer"
import LogoGallery from "../components/home/logoGallery/LogoGallery"
import HomeCatBoxes from "../components/home/homeCatBoxes/HomeCatBoxes"
import HowItWorks from "../components/home/howItWorks/HowItWorks"
import GoogleReviews from "../components/googleReviews/GoogleReviews"
import fetchReviews from "../fetch/FetchReviews"
import fetchPosts from "../fetch/FetchPosts"
import nextI18NextConfig from "../next-i18next.config.js"
import BlogsComponent from "../components/reusable/BlogsComponent"
import {useTranslation} from "next-i18next"
import MbpHead from "../components/head/MbpHead"
import HomeTextComponent from "../components/home/HomeTextComponent"
import BannerHomeSpringCampaign from "../components/home/BannerHomeSpringCampaign"
import getLandingPage from "../contentful/api/getLandingPage"
import {contentTypes} from "../contentful/contentTypes"
import getBlockTiles, {BlockTilesSlugs} from "../contentful/api/getBlockTiles"
import {getPageData} from "../pageData"
import DataContext from "../context/DataContext"
import InstagramSection from "../components/home/mybikeplanInstagram/InstagramSection"
import UspSection from "../components/home/UspSection"
import GoogleRbox from "../components/googleReviews/GoogleRbox"
import buildTimeFetchAccessories from "../fetch/BuildTimeFetchAccessories"
import buildTimeFetchBikes from "../fetch/BuildTimeFetchBikes"
import FindPerfectFurniture from "../components/home/FindPerfectFurniture/FindPerfectFurniture"
import RecentlyViewedProducts from "../components/home/RecentlyViewedProducts/RecentlyViewedProducts"

type Props = {
  reviews: any,
  posts: any[],
  bikesList: any[],
  landingPage: any,
  salesOrdersCount: number;
  salesOrders: [];
	accessoriesList: []
}
const Home: React.FC<Props> = ({reviews, posts, bikesList, landingPage, salesOrders, salesOrdersCount}) => {
	const {t} = useTranslation()
	const title = t("Möbel auf Raten | 0% Zins | ab Fr. 52 im Monat | Schweiz")
	const isBrandsForEmployees = process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE === "mhp_bfe"
	const description = isBrandsForEmployees ? "Kaufe unsere Möbel mit monatlichen Zahlungen zu 0 % Zins. Jetzt bestellen und stressfrei über 6-36 Monatsraten bezahlen!" : t("Kaufe Deine Einrichtung mit einer 0 % Zins Finanzierung ab CHF 52 / Monat. Jetzt bestellen und stressfrei in 12-36 Monatsraten abzahlen.")
	const {
		cover,
		coverMobile,
		coverUpperParagraph,
		coverBottomParagraph,
		introduction,
		whatMyBikePlanStandsForBlock,
		headers
	} = landingPage.fields
	const {pageProps, organization} = useContext(DataContext)
	const {contentfulPageData} = pageProps

	const handleDebug = async () => {
		return fetch("/api/debug/environment").then(async res => res.json())
	}

	useEffect(() => {
		handleDebug().then(res => console.log("Debug", res))
	}, [])

	return (
		<>
			<MbpHead>
				<title>
					{title}
				</title>
				<meta name="description" content={description}/>
				<meta property="og:title" content={title}/>
				<meta property="og:description" content={description}/>
			</MbpHead>
			<div id={"landing-page"}>
				<NavBar absolute={false}/>

				<div className="d-none d-md-block" style={{marginTop: -26}}>
					<BannerHomeSpringCampaign reviews={reviews} headers={headers} cover={cover}
						coverUpperParagraph={coverUpperParagraph}
						coverBottomParagraph={coverBottomParagraph}/>
				</div>
				<div className="d-block d-md-none" style={{marginTop: -26}}>
					<BannerHomeSpringCampaign reviews={reviews} cover={coverMobile ? coverMobile : cover}
						coverUpperParagraph={coverUpperParagraph}
						coverBottomParagraph={coverBottomParagraph}
						headers={headers}/>
				</div>

				<div className="container d-flex align-items-center justify-content-center mb-2" style={{height: 40}}>
					{reviews ?
						<GoogleRbox reviews={reviews}/> : null}
				</div>
				<div className="container mt-2 pb-4 d-none d-md-block">
					<UspSection/>
				</div>
				<div className="mt-5 pb-md-2 mt-md-0 pt-3">
					<RecentlyViewedProducts/>
				</div>
				<HomeCatBoxes content={introduction} hideTopModels/>
				<HowItWorks className="pt-5 pb-5" blockTilesHowItWorks={contentfulPageData.howItWorks}/>
				<FindPerfectFurniture/>
				{reviews &&
        <GoogleReviews reviews={reviews} IconWidth="24px"/>}
				<LogoGallery/>
				{posts.length > 0 &&
				<BlogsComponent posts={posts}/>}
				<InstagramSection/>
				<HomeTextComponent content={whatMyBikePlanStandsForBlock}/>
				<Footer/>
			</div>
		</>
	)
}

Home.propTypes = {
	reviews: PropTypes.any.isRequired,
	posts: PropTypes.array.isRequired,
	bikesList: PropTypes.array.isRequired
}

export async function getStaticProps({locale, params}) {
	const pageData = (await getPageData(locale))
	const bikesList = await buildTimeFetchBikes(locale, ["Marke"], ["none"], ["slug", "categories", "uvpPrice", "displayPrice", "price", "name", "images", "stock_status", "colorData", "discount", "customInstanceDiscounts", "totalPhysicalStock", "views"], null, null)

	return {
		props: {
			landingPage: await getLandingPage(locale, contentTypes.landingPage),
			...pageData,
			reviews: await fetchReviews(),
			posts: await fetchPosts(true, locale),
			accessoriesList: await buildTimeFetchAccessories(locale),
			bikesList,
			contentfulPageData: {
				howItWorks: await getBlockTiles(locale, BlockTilesSlugs.howMyBikePlanWorks),
				detailSection: await getBlockTiles(locale, BlockTilesSlugs.detailSection)
			},
			...(await serverSideTranslations(locale, ["common", "homePage", "homeDetails", "productDescrPage", "veloAfrica", "search", "filters", "insurance"], nextI18NextConfig))
		}
	}
}

export default Home
